import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import { EnvConfig } from '@model/config/env-config';
import { LogType } from '@model/common/logging';
import { ComponentContainer, SectionSeparator } from '@styles/layout';
import { mapCMSComponent } from '@util/contentful';
import { log } from '@util/logging';
import { SectionTitleSizes } from '@components/common/section-title';
import { Theme, ThemeProps } from '@theme/base';
import { ComponentData, getComponentsData } from '@state/components';
import { getPath } from '@state/app/routing/routingSelectors';
import { CMSDynamicComponentTypes } from '@model/contentful';
import { filterComponentsForBrand } from '@model/config/brands';
import { mq } from '@styles/breakpoints';
import { localiseObject } from '@util/localisation';

/* ***************** *
 *       Types       *
 * ***************** */
export interface CMSComponentsProps {
  theme?: Theme;
  testId?: string;
  components: Array<any>;
  titleSize?: SectionTitleSizes;
  fullWidth?: boolean;
  separatorSize?: number;
  cmsComponentsType?: CMSComponentTypes;
  displayIndexes?: Array<number>;
}

export enum CMSComponentTypes {
  MAIN = 'main',
  SIDEBAR = 'sidebar'
}

/* ***************** *
 *       Styles      *
 * ***************** */

const Container = styled.section(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.xxLarge,
  [mq.medium]: {
    marginBottom: theme.custom.spacing.xxxLarge
  }
}));

/* ******************* *
 *    CMSComponents    *
 * ******************* */

export const CMSComponents = (props: CMSComponentsProps) => {
  const { components, fullWidth, separatorSize, cmsComponentsType = CMSComponentTypes.MAIN, displayIndexes } = props;
  const path = useSelector(getPath);
  const windowPath = typeof window === 'undefined' ? path : `${window.location.pathname}${window.location.search}`;
  const componentsData: Array<ComponentData> = useSelector(getComponentsData(cmsComponentsType));
  if (!components || !components.length || (path && windowPath !== path)) {
    return null;
  }

  const renderableComponents = filterComponentsForBrand(components);
  const curatedProps: any = { ...props };
  delete curatedProps.components;

  /* *** RENDERERS *** */
  const renderComponents = () => {
    if (!componentsData.length) return null;

    if (componentsData.length && components.length !== componentsData.length) {
      log({
        text: `Components data doesn't seem to have been fetched correctly.\n\n${componentsData}\n\n${components}`,
        logType: LogType.ERROR
      });
      return null;
    }

    const renderedComponents = Array.isArray(displayIndexes)
      ? renderableComponents.map((component, index) => (displayIndexes.includes(index) ? component : undefined))
      : renderableComponents;

    return renderedComponents.map((cmsComponent: any, index: number) => {
      if (!cmsComponent) {
        return null;
      }
      const componentConfig: any = mapCMSComponent(cmsComponent.sys.contentType.sys.id);
      if (!componentConfig) {
        log({
          text: `CMS component "${cmsComponent.sys.contentType.sys.id}" has not been recognised and could not be mapped`,
          logType: LogType.ERROR
        });
        return null;
      }
      const Component = componentConfig.component;
      const isEdgeToEdge = componentConfig.isEdgeToEdge;
      const isDynamic = componentConfig.isDynamic;

      const Container = isDynamic ? Fragment : ComponentContainer;
      const isCMSSpacingComponent: boolean = cmsComponent?.fields?.componentId === CMSDynamicComponentTypes.spacing;
      const showSpacing: boolean =
        displayIndexes?.filter((index) => index >= 0).length !== 1 && index > 0 && !isCMSSpacingComponent;
      const appVariantId = EnvConfig.get().APP_VARIANT_ID;

      return (
        Component && (
          <Container key={`${cmsComponent.sys.id}-${index}`} {...(!isDynamic && { fullWidth, isEdgeToEdge })}>
            {showSpacing && <SectionSeparator size={separatorSize} data-testid={`component-separator-${index}`} />}
            <Component
              cmsConfig={localiseObject(appVariantId, cmsComponent.fields)}
              {...curatedProps}
              componentData={componentsData[index]?.data}
            />
          </Container>
        )
      );
    });
  };

  return <Container data-testid={props.testId || TestId.cmsComponents.main}>{renderComponents()}</Container>;
};
