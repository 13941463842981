import styled from '@emotion/styled';
import React from 'react';
import { TestId } from '@components/test-ids';
import { LazyLoadImage } from '@components/common/lazy-load';
import { RippleCard } from '@components/common/ripple-card';
import { mixins } from '@styles/mixins';
import { ThemeProps } from '@theme/base';

const Container: any = styled.div(({ theme }: ThemeProps) => ({
  ...mixins(theme).card,
  transition: `all ${theme.custom.transitions.default}`,
  cursor: 'pointer',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  ':hover': {
    ['p, h5']: {
      textDecoration: 'none',
      whiteSpace: 'pre-wrap',
      color: theme.custom.states.link.default,
      cursor: 'pointer'
    }
  }
}));

const ImageContainer: any = styled.div({
  paddingTop: '50%',
  position: 'relative'
});

const Image: any = styled(LazyLoadImage)({
  minHeight: '100%',
  minWidth: '100%',
  position: 'absolute',
  top: 0,
  WebkitUserSelect: 'none' /* disable selection/Copy of UIWebView */,
  WebkitTouchCallout: 'none' /* disable the IOS popup when long-press on a link */
});

const ContentContainer: any = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium
}));

const Subtitle: any = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  textTransform: 'uppercase',
  color: theme.custom.colors.group4.light,
  margin: 0,
  marginBottom: theme.custom.spacing.xSmall
}));

const Title: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  fontWeight: 500,
  margin: 0,
  color: theme.custom.colors.group4.base
}));

export interface BaseCardProps {
  subtitle?: string;
  title?: string;
  image: string;
  sizes?: string;
}

export const BaseCard = ({ subtitle, title, image, sizes }: BaseCardProps) => {
  return (
    <RippleCard>
      <Container data-testid={TestId.recentlyViewed.baseCard}>
        {image && (
          <ImageContainer>
            <Image src={image} width={294} height={147} sizes={sizes} />
          </ImageContainer>
        )}
        <ContentContainer>
          <Subtitle>{subtitle}</Subtitle>
          <Title>{title}</Title>
        </ContentContainer>
      </Container>
    </RippleCard>
  );
};
