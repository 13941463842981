import uri from 'urijs';

export const getHomeMeta = () => ({
  '@context': 'http://schema.org',
  '@type': 'TravelAgency',
  name: 'Mercury Holidays',
  description: 'Handpicked holidays, escorted tours and cruises since 1980.',
  url: uri().href(),
  image: 'https://image-stock.s3-eu-west-1.amazonaws.com/original/random/mercury-logo-675x4746.png',
  logo: 'https://image-stock.s3-eu-west-1.amazonaws.com/original/random/mercury-logo-675x4746.png',
  award: 'Winner of seven British Travel Awards 2019',
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: 90,
    bestRating: 100,
    worstRating: 0,
    ratingCount: 1183
  },
  location: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'UK',
      addressRegion: 'Kent',
      addressLocality: 'Sevenoaks',
      postalCode: 'TN13 1YL',
      streetAddress: "St John's House, Suffolk Way"
    }
  },
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'UK',
    addressRegion: 'Kent',
    addressLocality: 'Sevenoaks',
    postalCode: 'TN13 1YL',
    streetAddress: "St John's House, Suffolk Way"
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '51.272423',
    longitude: '0.1935094'
  },
  hasMap:
    'https:www.google.co.uk/maps/place/Mercury+Holidays/@51.272423,0.1935094,18z/data=!4m5!3m4!1s0x47df4dfa1aec5cad:0xb07e5e883c480cd3!8m2!3d51.2728753!4d0.193744',
  telephone: '01732 775000',
  openingHours: ['Mo,Tu,We,Th,Fr 09:00-17:30', 'Sa 09:00-16:00', 'Su 09:00-16:00'],
  sameAs: [
    'https:www.facebook.com/mercuryholidays',
    'https:twitter.com/MercuryHolidays',
    'https:plus.google.com/+MercuryDirect',
    'https:www.pinterest.com/mercuryholidays/',
    'https:www.instagram.com/mercuryholidays/'
  ]
});
