import { Theme, ThemeProps } from '@theme/base';
import { useSelector } from 'react-redux';
import { getRecentlyViewed, RecentlyViewedItem } from '@state/app';
import React from 'react';
import { RecentlyViewed } from '@components/recently-viewed/RecentlyViewed';
import { ComponentContainer, SectionContainer } from '@styles/layout';
import styled from '@emotion/styled';
import { useI18NextContext } from '@components/hooks';
import { AppVariant } from '@model/config/brands';

export interface RecentlyViewedCarouselContainerProps {
  theme?: Theme;
}

const Container = styled(ComponentContainer)(({ theme }: ThemeProps) => ({
  paddingTop: theme.custom.spacing.xxxLarge,
  paddingBottom: theme.custom.spacing.xxxLarge
}));

export const RecentlyViewedCarouselContainer = (props: RecentlyViewedCarouselContainerProps) => {
  const isWhiteLabel = AppVariant.isWhitelabel();

  if (isWhiteLabel) {
    return null;
  }

  const recentlyViewed: Array<RecentlyViewedItem> = useSelector(getRecentlyViewed);
  const t: any = useI18NextContext();
  if (!recentlyViewed.length) return null;
  return (
    <SectionContainer>
      <Container>
        <RecentlyViewed {...props} recentlyViewed={recentlyViewed} title={t('recently-viewed__header--label')} />
      </Container>
    </SectionContainer>
  );
};
