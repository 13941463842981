import Link, { LinkProps } from 'next/link';
import { DestinationLinkBuilder } from '@model/iceberg/geography';
import React from 'react';
import { TripTypes } from '@model/contentful';
import { TestId } from '@components/test-ids';
import { PlainLinkStyles } from '@styles/common';

export interface TourLinkProps {
  path: string;
  children?: JSX.Element | string;
  tripType?: TripTypes;
  onMouseDown?: (event: any) => void;
}

export const TourLink = ({ path, children, tripType, onMouseDown }: TourLinkProps) => {
  const linkBuilder: DestinationLinkBuilder = new DestinationLinkBuilder();
  const link: LinkProps = linkBuilder.getTourLink(path, tripType);
  return path ? (
    <Link
      prefetch={false}
      {...link}
      passHref
      style={PlainLinkStyles}
      data-testid={TestId.tourLink}
      onMouseDown={onMouseDown}
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};
