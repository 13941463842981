import React from 'react';
import { useAsync } from 'react-use';
import { map } from 'lodash';
import { withTheme } from '@emotion/react';
import { HotelContentApi } from '@model/iceberg/service/hotel-content/hotel-content-api';
import { RecentlyViewedItem, RecentlyViewedType } from '@state/recently-viewed';
import { FITRecentlyViewedCard } from './card/FITRecentlyViewedCard';
import { ToursRecentlyViewedCard } from './card/ToursRecentlyViewedCard';
import { TestId } from '@components/test-ids';
import { Carousel, MAX_SLIDES_TO_SHOW } from '@components/common';

export interface RecentlyViewedProps {
  title: string;
  recentlyViewed: Array<RecentlyViewedItem>;
}

export const RecentlyViewedComponent = ({ title, recentlyViewed }: RecentlyViewedProps) => {
  const recentlyViewedFit = recentlyViewed.filter(({ type }) => type === RecentlyViewedType.FIT);
  const hotelTileResponse = useAsync(async () => await new HotelContentApi().tiles(map(recentlyViewedFit, 'id')), []);

  if (
    recentlyViewedFit.length > 0 &&
    (hotelTileResponse.loading || !hotelTileResponse.value || !hotelTileResponse.value.data)
  )
    return null;

  const renderRecentlyViewed = ({ id, type, tripType }: RecentlyViewedItem) =>
    type === RecentlyViewedType.TOURS ? (
      <ToursRecentlyViewedCard id={id} key={`${id}-${tripType}`} />
    ) : (
      <FITRecentlyViewedCard
        key={`${id}-${tripType}`}
        hotel={hotelTileResponse?.value?.data.find(({ place: { hotel } }) => hotel?.path === id)}
      />
    );

  return (
    <Carousel
      testId={TestId.recentlyViewed.container}
      title={title}
      alignLeft={recentlyViewed.length <= MAX_SLIDES_TO_SHOW}
      slidesToShow={MAX_SLIDES_TO_SHOW}
    >
      {recentlyViewed.map(renderRecentlyViewed)}
    </Carousel>
  );
};

export const RecentlyViewed = withTheme(RecentlyViewedComponent);
