import { Name } from '@model/iceberg';
import { DestinationLink } from '@components/common/next-link';
import React from 'react';
import { BaseCard } from '@components/recently-viewed/card/BaseCard';
import { useI18NextContext } from '@components/hooks';
import { HotelTile } from '@model/iceberg/hotel-content/hotel-content';
import { breakpoints } from '@styles/breakpoints';

export interface RecentlyViewedCardProps {
  hotel?: HotelTile;
}

export const FITRecentlyViewedCard = ({ hotel }: RecentlyViewedCardProps) => {
  const t: any = useI18NextContext();

  if (!hotel) return null;
  const { featuredImage, place } = hotel;
  const name: Name = hotel.place.region || hotel.place.country.name;

  return (
    <DestinationLink path={place.hotel!.path}>
      <BaseCard
        subtitle={`${name.display} ${t('common__label--holidays')}`}
        title={place.hotel?.display || ''}
        image={featuredImage.url}
        sizes={`(max-width: ${breakpoints.small}) 100vw, (max-width: ${breakpoints.medium}) 50vw, (max-width: ${breakpoints.large}) 25vw, 294px`}
      />
    </DestinationLink>
  );
};
