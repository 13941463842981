import React, { MouseEventHandler } from 'react';
import Link from 'next/link';
import { ParsedUrlQuery } from 'querystring';
import { TripTypes } from '@model/contentful';
import { TestId } from '@components/test-ids';
import { PlainLinkStyles } from '@styles/common';
import { getTourProductUrl } from '@util/tours';

export interface TourProductLinkProps {
  path: string;
  children?: JSX.Element | string;
  tripType: TripTypes;
  query?: ParsedUrlQuery;
  onClick?: MouseEventHandler;
}

export const TourProductLink = ({ path, query, children, tripType, onClick }: TourProductLinkProps) => {
  const link = getTourProductUrl({ productPath: path, tripType, ...query });

  return path ? (
    <Link prefetch={false} href={link} passHref style={PlainLinkStyles} onClick={onClick} data-testid={TestId.tourLink}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};
