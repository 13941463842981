import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Theme, ThemeProps } from '@theme/base';
import { mq, breakpoints } from '@styles/breakpoints';
import { CarouselTile as CarouselTileModel } from '@model/content';
import { performNavigate } from '@state/app';
import { Button, ButtonVariant } from '@components/material-ui';
import { getImageUrl } from '@util/images';

/* ***************** *
 *       Types       *
 * ***************** */
export interface HomeCarouselTileProps extends CarouselTileModel {
  theme?: Theme;
  testId?: string;
  imagesOnly?: boolean;
}

/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps extends ThemeProps {
  bgImages: Array<string>;
  isLink?: boolean;
}
const Container: any = styled.div(({ theme, bgImages, isLink }: ContainerProps) => ({
  backgroundImage: `url(${bgImages[0]})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'top',
  color: theme.custom.colors.white,
  height: 'unset',
  padding: `0 ${theme.custom.spacing.medium * 2}px`,
  paddingBottom: '130%',
  textAlign: 'center',
  cursor: isLink ? 'pointer' : 'auto',
  [mq.small]: {
    backgroundImage: `url(${bgImages[1]})`,
    paddingBottom: '50%'
  },
  [mq.large]: {
    backgroundImage: `url(${bgImages[2]})`,
    paddingBottom: '33.33%'
  }
}));

const InnerContainer: any = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  margin: 'auto',
  maxWidth: 600
});

const Heading: any = styled.h1(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h1 as any),
  marginBottom: theme.custom.spacing.small,
  textShadow: '0 2px 4px rgba(0,0,0,0.6)',
  [mq.small]: {
    fontSize: '6rem'
  }
}));

const Message: any = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginBottom: theme.custom.spacing.large,
  textShadow: '0 2px 4px rgba(0,0,0,0.6)',
  [mq.small]: {
    fontSize: '2.5rem'
  }
}));

const CtaButton: any = styled(Button)(({ theme }: ThemeProps) => ({
  ['.MuiButtonBase-root']: {
    backgroundColor: `${theme.custom.colors.group2.base}40`,
    borderColor: theme.custom.colors.white,
    borderRadius: 10,
    color: theme.custom.colors.white,
    padding: `${theme.custom.spacing.medium / 2}px ${theme.custom.spacing.medium}px`,
    textShadow: '0 2px 4px rgba(0,0,0,0.6)',
    width: 'unset',
    ['&:hover']: {
      backgroundColor: `${theme.custom.colors.group2.base}`
    }
  }
}));
/* *********************** *
 *    HomeCarouselTileComponent    *
 * *********************** */

export const HomeCarouselTileComponent = ({
  heading,
  message,
  image,
  tabletImage,
  mobileImage,
  ctaLabel,
  link,
  imagesOnly
}: HomeCarouselTileProps) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(performNavigate(link));
  };
  const responsiveImages = [
    getImageUrl(mobileImage.url || image.url, breakpoints.small),
    getImageUrl(tabletImage.url || image.url, breakpoints.large),
    getImageUrl(image.url, breakpoints.xLarge)
  ];
  const tileIsLink = link && imagesOnly;

  return (
    <Container bgImages={responsiveImages} onClick={tileIsLink ? handleClick : null} isLink={tileIsLink}>
      {!imagesOnly && (
        <InnerContainer>
          {heading && <Heading>{heading}</Heading>}
          {message && <Message>{message}</Message>}
          {ctaLabel && <CtaButton onClick={handleClick} label={ctaLabel} variant={ButtonVariant.OUTLINED} />}
        </InnerContainer>
      )}
    </Container>
  );
};

export const HomeCarouselTile = withTheme(HomeCarouselTileComponent);
