import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { ComponentContainer } from '@styles/layout';
import { CarouselTile } from '@model/content';
import { SectionTitle, SectionTitleSizes, TextMarkdown, FixedCarousel } from '@components/common';
import { UspTile } from './UspTile';

export interface UspProps {
  theme?: Theme;
  testId?: string;
  tiles: Array<CarouselTile>;
}

const StyledTextDescription = styled(TextMarkdown)(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.xLarge
}));

export const UspComponent = ({ tiles, testId }: UspProps) => {
  const t: any = useI18NextContext();
  const renderTiles = (tiles: Array<CarouselTile>) => {
    return tiles.map((tile) => <UspTile key={tile.heading} {...tile} />);
  };
  return (
    <ComponentContainer data-testid={testId || TestId.usp.main}>
      <SectionTitle size={SectionTitleSizes.LARGE} heading={t('usp__header')} main />
      <StyledTextDescription description={t('usp__text')} />
      <FixedCarousel tiles={tiles} renderTiles={renderTiles} testId={TestId.usp.carousel} />
    </ComponentContainer>
  );
};

export const Usp = withTheme(UspComponent);
