import React from 'react';
import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { mixins } from '@styles/mixins';
import { withTheme } from '@emotion/react';
import { Icons, formatIconName } from '@model/common';
import { ThemeProps } from '@theme/base';
import { Icon } from '@components/common/generic-icon';
import { TestId } from '@components/test-ids';
import { CarouselTileProps } from '@components/common/carousel';

const Container = styled.div(({ theme }: ThemeProps) => ({
  alignItems: 'left',
  border: `1px solid ${theme.custom.colors.group10.light}`,
  borderRadius: 10,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  marginRight: 4,
  padding: theme.custom.spacing.medium,
  textAlign: 'left',
  [mq.medium]: {
    height: '100%'
  }
}));

const Title = styled.div(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.xSmall,
  ...mixins(theme).truncate,
  marginTop: 0,
  ...(theme.custom.typography.h5 as any)
}));

const Text = styled.p(({ theme }: ThemeProps) => ({
  flexGrow: 1,
  ...(theme.custom.typography.paragraph as any).copy,
  marginBottom: 0,
  width: '100%'
}));

const StyledIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  color: theme.custom.states.info.dark,
  marginBottom: 10,
  fontSize: '6rem'
}));

export const UspTileComponent = ({ testId, heading, message, icon }: CarouselTileProps) => {
  return (
    <Container data-testid={testId || TestId.dynamicCarousel.tile}>
      {icon && <StyledIcon name={Icons[formatIconName(icon)]} />}
      <Title>{heading}</Title>
      <Text>{message}</Text>
    </Container>
  );
};

export const UspTile = withTheme(UspTileComponent);
